<template>
  <div class="wrapper">
    <div class="mb-4">
      <h3>리더십 진단 인원 관리</h3>
    </div>

    <article class="add-excel">
      <table class="info">
        <tbody>
          <tr>
            <td class="label text-center">엑셀 일괄등록</td>
            <td class="p-2">
              <input type="file" @change="event => changeFile(event)" />
              <div class="d-flex justify-content-end gap-2"></div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="add-excel-button">
        <b-button @click="downloadDiagExample" variant="success">양식 다운로드</b-button>
        <b-button @click="uploadFile" variant="primary">적용하기</b-button>
      </div>
    </article>

    <article>
      <b-tabs content-class="mt-3">
        <b-tab title="전체" active value="전체" @click="clickTab($event)"></b-tab>
        <b-tab title="진단대상자별" value="진단대상자별" @click="clickTab($event)"></b-tab>
      </b-tabs>

      <div class="d-flex gap-2 mb-4">
        <div v-if="state.searchObj.searchMenu != '진단대상자별'">
          <select class="form-select" v-model="state.searchObj.searchStatus" @change="searchList($event)">
            <option value="">전체상태</option>
            <option value="미진단">미진단</option>
            <option value="진단중">진단중</option>
            <option value="진단완료">진단</option>
          </select>
        </div>
        <div v-if="state.searchObj.searchMenu != '진단대상자별'">
          <select class="form-select fl" v-model="state.searchObj.searchType" @change="chagneFilter($event)">
            <option value="diag_subject_name">진단대상자</option>
            <option value="relationship">관계</option>
            <option value="diag_subject_part">소속부서</option>
            <option value="diag_mobile">핸드폰번호</option>
            <option value="diag_subject_id">진단대상자ID</option>
            <option value="diag_name">진단자</option>
            <option value="diag_id">진단자ID</option>
            <option value="diag_subject_mobile">진단자 핸드폰번호</option>
            <option value="diagnostic_code">진단코드</option>
          </select>
        </div>
        <div v-if="state.searchObj.searchMenu == '진단대상자별'">
          <select class="form-select fl" v-model="state.searchObj.searchType" @change="chagneFilter($event)">
            <option value="diag_subject_name">진단대상자</option>
            <option value="diag_subject_id">진단대상자ID</option>
          </select>
        </div>
        <div>
          <input type="text" v-model="state.searchObj.searchText" class="form-control fl" />
        </div>
        <div>
          <b-button variant="primary" v-if="state.searchObj.searchMenu == '진단대상자별'" @click="searchSubhList">검색</b-button>
          <b-button variant="primary" v-else @click="searchList">검색</b-button>
        </div>
      </div>

      <div class="d-flex justify-content-between mb-4">
        <table v-if="state.searchObj.searchMenu != '진단대상자별'" class="ta_info">
          <tbody>
            <tr>
              <th>진단 대상자 수</th>
              <th>진단 진행</th>
              <th>진단 현황</th>
            </tr>
            <tr>
              <td>{{ state.statistics.statisticsSubjectCnt }}</td>
              <td>{{ state.statistics.statisticsListCnt }}</td>
              <td>{{ state.statistics.statisticsCompleteCnt }} / {{ state.statistics.statisticsListCnt }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else></div>

        <div>
          <div class="d-flex justify-content-end mb-2">* 미진단자 재전송은 메일, 문자로 모두 발송됩니다.</div>
          <div class="d-flex justify-content-end gap-2">
            <b-button v-if="state.searchObj.searchMenu == '전체'" variant="outline-danger" @click="deleteItem">삭제</b-button>
            <b-button variant="outline-success" @click="excelDownLoad()">엑셀 다운로드</b-button>
            <b-button
              v-if="state.searchObj.searchMenu == '전체'"
              :disabled="state.diagnosisList.length === 0"
              :variant="state.diagnosisList.length === 0 ? 'secondary' : 'outline-info'"
              @click="setAnnouncementModal('메일', '진단', '')"
              >메일 안내 발송</b-button
            >
            <b-button
              v-if="state.searchObj.searchMenu == '전체'"
              :disabled="state.diagnosisList.length === 0"
              :variant="state.diagnosisList.length === 0 ? 'secondary' : 'outline-primary'"
              @click="setMessageModal(true)"
              >문자 안내 발송</b-button
            >
            <b-button
              v-if="state.searchObj.searchMenu == '전체'"
              :disabled="state.diagnosisList.length === 0"
              :variant="state.diagnosisList.length === 0 ? 'secondary' : 'outline-secondary'"
              @click="setAnnouncementModal('미진단', '미진단', '')"
              >미진단자 재전송</b-button
            >
            <b-button @click="setAddDiagModal" variant="outline-danger" v-if="state.searchObj.searchMenu == '전체'">진단자 개별추가</b-button>
          </div>
        </div>
      </div>
      <div>
        <table class="table table-hover text-center mb-4" v-if="state.searchObj.searchMenu == '전체'">
          <thead>
            <tr>
              <th><input type="checkbox" @click="allChkBox($event)" v-model="state.allCheck" class="cursor" /></th>
              <th scope="col">No</th>
              <th scope="col">관계</th>
              <th scope="col">소속부서</th>
              <th scope="col">진단대상자</th>
              <th scope="col">진단대상자ID</th>
              <th scope="col">진단자</th>
              <th scope="col">진단자ID</th>
              <th scope="col">진단자 핸드폰번호</th>
              <th scope="col">진단코드</th>
              <th scope="col">현황</th>
              <th scope="col">진단완료일</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr v-if="state.diagnosisList == 0">
              <td colspan="13">정보가 없습니다.</td>
            </tr>
            <tr v-for="item in state.diagnosisList" :key="item.index">
              <th><input type="checkbox" v-model="item.isChecked" @click="clickChkBox(item.idx, $event)" class="cursor" /></th>
              <td>{{ item.rowNum }}</td>
              <td>{{ item.relationship }}</td>
              <td>{{ item.diag_subject_part }}</td>
              <td>{{ item.diag_subject_name }}</td>
              <td>{{ item.diag_subject_id }}</td>
              <td>{{ item.diag_name }}</td>
              <td>{{ item.diag_id }}</td>
              <td>{{ addStarNumber(item.diag_mobile) }}</td>
              <td>{{ item.diagnostic_code }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.diag_end_date }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table table-hover text-center mb-4" v-if="state.searchObj.searchMenu == '진단대상자별'">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">진단대상자</th>
              <th scope="col">진단대상자ID</th>
              <th scope="col">현황</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr v-if="state.diagnosisList == 0">
              <td colspan="12">정보가 없습니다.</td>
            </tr>
            <tr v-for="(item, index) in state.diagnosisSubList" :key="item.index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.diag_subject_name }}</td>
              <td>{{ item.diag_subject_id }}</td>
              <td>{{ item.diag_success_total + '/' + item.diag_total }}</td>
            </tr>
          </tbody>
        </table>

        <div>
          <Paging :totaldata="state.pagingObj.pagingTotal" :pagingdata="50" :pagingrange="5" :setPage="setPage" @onPagingEvent="onMovePageing"></Paging>
        </div>
      </div>

      <!-- 메세지 추가 모달  -->
      <MessageModal
        v-if="state.isMessageModal"
        :groupValues="state.groupValues"
        :diagnosisList="state.diagnosisList"
        :checkList="state.checkList"
        :visible="state.isMessageModal"
        :isAnnouncementModal="state.isAnnouncementModal"
        @sendMassage="setAnnouncementModal"
        @closeModal="setMessageModal(false)"></MessageModal>

      <!-- 알림  -->
      <AnnouncementModal
        v-if="state.isAnnouncementModal"
        :count="state.checkList"
        :kind="state.announcementType"
        :visible="state.isAnnouncementModal"
        @closeModal="state.isAnnouncementModal = false"
        @sendMassage="guideSend(announcementType)"></AnnouncementModal>
    </article>

    <!-- 진단자 개별 추가 modal  -->
    <div class="popup-wrapper" v-if="state.isAddDiagModal">
      <div class="white-box">
        <div @click="setAddDiagModal" class="close">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg cursor" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </div>
        <p class="title">진단자 개별 추가</p>
        <p class="text-danger text-right mb-2">모든 항목은 필수값 입니다.</p>
        <ul>
          <li class="mb-4">
            <div class="d-flex align-items-center gap-2">
              <p class="flex-none w-150">진단자-진단대상자 관계</p>
              <div class="w-full">
                <b-form-select v-model="state.diagInfo.relationship">
                  <b-form-select-option value="본인">본인</b-form-select-option>
                  <b-form-select-option value="상사">상사</b-form-select-option>
                  <b-form-select-option value="동료">동료</b-form-select-option>
                  <b-form-select-option value="구성원">구성원</b-form-select-option>
                </b-form-select>
              </div>
            </div>
          </li>
          <li class="mb-2">
            <div class="d-flex align-items-center gap-2">
              <p class="flex-none w-150">진단대상자 소속부서</p>
              <div class="w-full">
                <b-form-input v-model="state.diagInfo.diag_subject_part" type="text"></b-form-input>
              </div>
            </div>
          </li>
          <li class="mb-2">
            <div class="d-flex align-items-center gap-2">
              <p class="flex-none w-150">진단대상자 이름</p>
              <div class="w-full">
                <b-form-input v-model="state.diagInfo.diag_subject_name" type="text"></b-form-input>
              </div>
            </div>
          </li>
          <li class="mb-2">
            <div class="d-flex align-items-center gap-2">
              <p class="flex-none w-150">진단대상자 ID</p>
              <div class="w-full">
                <b-form-input v-model="state.diagInfo.diag_subject_id" type="text"></b-form-input>
              </div>
            </div>
          </li>
          <li class="mb-4">
            <div class="d-flex align-items-center gap-2">
              <p class="flex-none w-150">진단대상자 핸드폰번호</p>
              <div class="w-full">
                <input v-model="state.diagInfo.diag_subject_mobile" @input="event => setSubjectMobileValue(event)" type="text" class="form-control" />
              </div>
            </div>
          </li>
          <li class="mb-2">
            <div class="d-flex align-items-center gap-2">
              <p class="flex-none w-150">진단자 소속부서</p>
              <div class="w-full">
                <b-form-input v-model="state.diagInfo.diag_part" type="text"></b-form-input>
              </div>
            </div>
          </li>
          <li class="mb-2">
            <div class="d-flex align-items-center gap-2">
              <p class="flex-none w-150">진단자 이름</p>
              <div class="w-full">
                <b-form-input v-model="state.diagInfo.diag_name" type="text"></b-form-input>
              </div>
            </div>
          </li>
          <li class="mb-2">
            <div class="d-flex align-items-center gap-2">
              <p class="flex-none w-150">진단자 ID</p>
              <div class="w-full">
                <b-form-input v-model="state.diagInfo.diag_id" type="text"></b-form-input>
              </div>
            </div>
          </li>
          <li class="mb-4">
            <div class="d-flex align-items-center gap-2">
              <p class="flex-none w-150">진단자 핸드폰번호</p>
              <div class="w-full">
                <input v-model="state.diagInfo.diag_mobile" @input="event => setMobileValue(event)" type="text" class="form-control" />
              </div>
            </div>
          </li>
        </ul>
        <div class="text-center">
          <b-button @click="addDiag" variant="primary">등록하기</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, watch } from '@vue/runtime-core';
import { ref, reactive } from '@vue/reactivity';
import { _axios } from '@/utils/axios';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import AnnouncementModal from '@/components/AnnouncementModal.vue';
import MessageModal from '@/components/MessageModal.vue';
import { faSlideshare } from '@fortawesome/free-brands-svg-icons';
export default {
  name: 'DiagnosisManagementDetail',
  components: {
    AnnouncementModal,
    MessageModal,
  },
  setup() {
    const setPage = ref(1);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      diagnosisList: [],
      diagnosisSubList: [],
      allCheck: false,
      checkList: new Set(),
      isModify: false,
      ResistIdx: '',
      groupValues: {
        groupStartData: '',
        groupEndData: '',
        groupName: '',
        groupManagerName: '',
        groupManagerEmailTtile: '',
        groupExcelFile: '',
        contactEnquiry: '',
        companyName: '',
        groupNameShow: '',
        companySubDomain: '',
      },
      statistics: {
        statisticsListCnt: 0, // 진단 진행
        statisticsSubjectCnt: 0, // 진단 대상자수
        statisticsCompleteCnt: 0, // 진단완료
      },
      searchObj: {
        searchMenu: '전체',
        searchNameList: new Set(),
        searchSelectName: '',
        searchText: '',
        searchStatus: '',
        searchType: 'diag_subject_name',
      },
      pagingObj: {
        pagingTotal: 50,
        pageNum: 1,
        pageSize: 50,
      },
      insertChk: true,
      sendChk: true,
      isAnnouncementModal: false, // 안내 모달의 on / off
      announcementType: '메일', // 안내 모달의 타입 + 메일, 문자, 미진단 구분
      isMessageModal: false, // 문자 전송 모달 on / off
      diagnosisType: '진단', // 진단 인지 미진단인지 확인
      messageText: '',

      isAddDiagModal: false, //진단자 개별추가 modal
      diagInfo: {
        diag_id: '',
        diag_mobile: '',
        diag_name: '',
        diag_part: '',
        diag_subject_id: '',
        diag_subject_mobile: '',
        diag_subject_name: '',
        diag_subject_part: '',
        idx_leadership_group: route.query.idx,
        relationship: '본인',
      },
    });

    const setMobileValue = event => {
      if (typeof Number(event.data) === 'string' || state.diagInfo.diag_mobile.length === 14) {
        state.diagInfo.diag_mobile = state.diagInfo.diag_mobile.substring(0, state.diagInfo.diag_mobile.length - 1);
        return;
      }

      //휴대폰번호는 -가 포함된 숫자이어야 한다.
      state.diagInfo.diag_mobile = state.diagInfo.diag_mobile
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
        .replace(/\-{1,2}$/g, '');
    };
    const setSubjectMobileValue = event => {
      if (typeof Number(event.data) === 'string' || state.diagInfo.diag_subject_mobile.length === 14) {
        state.diagInfo.diag_subject_mobile = state.diagInfo.diag_subject_mobile.substring(0, state.diagInfo.diag_subject_mobile.length - 1);
        return;
      }
      //휴대폰번호는 -가 포함된 숫자이어야 한다.
      state.diagInfo.diag_subject_mobile = state.diagInfo.diag_subject_mobile
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
        .replace(/\-{1,2}$/g, '');
    };

    const addDiag = async () => {
      for (const data in state.diagInfo) {
        if (state.diagInfo[data] === '') {
          alert('입력을 확인해주세요.');
          return;
        }
      }
      const result = await _axios.post(`/admin/group/diagList`, state.diagInfo, {});

      const { success, message } = result.data;
      if (success) {
        alert(message);
        await getGroup(route.query.idx);
        await getGroupList(route.query.idx);
        await getList(route.query.idx);
        state.ResistIdx = route.query.idx;
        setAddDiagModal();
      } else {
        alert(message);
      }
    };

    const setAddDiagModal = () => {
      state.isAddDiagModal = !state.isAddDiagModal;
      state.diagInfo = {
        diag_id: '',
        diag_mobile: '',
        diag_name: '',
        diag_part: '',
        diag_subject_id: '',
        diag_subject_mobile: '',
        diag_subject_name: '',
        diag_subject_part: '',
        idx_leadership_group: route.query.idx,
        relationship: '본인',
      };
    };

    let tempExcel = '';

    const addStarNumber = phoneNumber => {
      const splitPhoneNumber = phoneNumber.split(/-|\s+/);
      splitPhoneNumber.pop();
      return `${splitPhoneNumber.join('-')}-****`;
    };

    const setMessageModal = aValue => {
      state.isMessageModal = aValue;
    };

    async function getList(idx_leadership_group) {
      let tempobj = {
        idx_leadership_group,
        pageNum: state.pagingObj.pageNum,
        status: state.searchObj.searchStatus,
      };
      tempobj[state.searchObj.searchType] = state.searchObj.searchText;
      await _axios
        .get(`/admin/diag/list`, {
          params: tempobj,
        })
        .then(res => {
          state.pagingObj.pagingTotal = res.data.data.total;
          const { list } = res.data.data;
          state.diagnosisList = list;
          state.searchObj.searchNameList.clear();
          state.allCheck = false;
          state.diagnosisList.forEach(el => {
            el.isChecked = false;
            state.searchObj.searchNameList.add(el.diag_subject_name);
          });
        })
        .catch(err => {
          alert(`getList err : ${err}`);
        });
    }
    async function getSubjectList(idx_leadership_group) {
      state.pagingObj.pageNum = 1;
      let tempobj = {
        idx_leadership_group,
        pageNum: state.pagingObj.pageNum,
      };
      tempobj[state.searchObj.searchType] = state.searchObj.searchText;
      await _axios
        .get(`/admin/diag/subjectList`, {
          params: tempobj,
        })
        .then(res => {
          setPage.value = 1;
          state.pagingObj.pagingTotal = res.data.data.total;
          const { list } = res.data.data;
          state.diagnosisSubList = list;
        })
        .catch(err => {
          alert(`subjectList err : ${err}`);
        });
    }

    function allChkBox($event) {
      if ($event.target.checked == true) {
        state.allCheck = true;
        state.diagnosisList.forEach(el => {
          state.checkList.add(el.idx);
          el.isChecked = true;
        });
      } else {
        state.allCheck = false;
        state.checkList.clear();
        state.diagnosisList.forEach(el => {
          el.isChecked = false;
        });
      }
    }

    function clickChkBox(idx, $event) {
      if ($event.target.checked == false) {
        state.allCheck = false;
        state.checkList.delete(idx);
        state.diagnosisList.forEach(el => {
          if (idx == el.idx) el.isChecked = false;
        });
      } else {
        state.checkList.add(idx);
        state.diagnosisList.forEach(el => {
          if (idx == el.idx) el.isChecked = true;
        });
        let chkAllCheck = state.diagnosisList.find(el => el.isChecked == false);
        if (chkAllCheck == undefined) {
          state.allCheck = true;
        }
      }
    }

    async function getGroup(idx) {
      await _axios
        .get(`/admin/group/${idx}`, {})
        .then(res => {
          const { data } = res.data;
          state.groupValues.groupManagerName = data.contact_manager;
          state.groupValues.groupManagerEmailTtile = data.contact_title;
          state.groupValues.groupEndData = data.end_date;
          state.groupValues.groupName = data.group_name;
          state.groupValues.groupStartData = data.start_date;
          state.groupValues.contactEnquiry = data.contact_enquiry;
          state.groupValues.companyName = data.company_name;
          state.groupValues.groupNameShow = data.group_name_show;
          state.groupValues.companySubDomain = data.company_sub_domain;
        })
        .catch(err => {
          if (route.query.uid == undefined) {
            alert('인트라넷을 통해 접속해주세요');
            window.location.href = `http://carrotians.net/`;
          } else {
            alert(`group err : ${err}`);
          }
        });
    }

    async function getGroupList(idx) {
      await _axios
        .get(`/admin/group/list`, {
          params: {
            idx,
            pageNum: 1,
          },
        })
        .then(res => {
          const { list } = res.data.data;
          state.statistics.statisticsListCnt = list[0].diagListCnt;
          state.statistics.statisticsSubjectCnt = list[0].diagSubjectCnt;
          state.statistics.statisticsCompleteCnt = list[0].diagCompleteCnt;
        })
        .catch(err => {
          alert(`group/list err : ${err}`);
        });
    }

    function changeFile($event) {
      tempExcel = $event.target.files[0];
    }
    const downloadDiagExample = async () => {
      window.location.href = `https://kr.object.ncloudstorage.com/leadership/excel/leadershipDiagExample.xlsx`;
    };
    const uploadFile = async () => {
      if (!tempExcel) {
        alert('엑셀을 등록해주세요.');
        return;
      }
      const formData = new FormData();
      formData.append('diaglist_file', tempExcel);
      formData.append('idx_leadership_group', route.query.idx);

      const result = await _axios.post(`/admin/group/diagListExcel`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { success, message } = result.data;
      alert(message);
      if (success) {
        tempExcel = '';
        await getGroup(route.query.idx);
        await getGroupList(route.query.idx);
        await getList(route.query.idx);
        state.ResistIdx = route.query.idx;
      }
    };

    async function deleteItem() {
      let chkState = false;
      let chkconfirm = false;
      if (state.allCheck && state.searchObj.searchStatus == '' && state.searchObj.searchText == '') {
        //전체삭제
        if (window.confirm(`전체 삭제 하시겠습니까?`)) {
          if (window.confirm(`등록된 모든 데이터가 삭제 됩니다`)) {
            await _axios
              .delete(`/admin/diag/deleteAll/${state.ResistIdx}`, {})
              .then(() => {
                alert(`전체삭제가 완료되었습니다.`);
              })
              .then(() => {
                getList(state.ResistIdx);

                getGroupList(state.ResistIdx);
              });
          }
        }
      } else {
        state.diagnosisList.forEach(el => {
          state.checkList.forEach(element => {
            if (element == el.idx) {
              // API 에서 받아온 리스트와 체크박스 체크된 리스트와 동일한 경우
              if (el.status == '진단중' || el.status == '진단완료') {
                chkState = true;
              }
            }
          });
        });
        if (chkState) {
          chkconfirm = window.confirm(`진행중 또는 진행완료 된 건들이 포함 되어 있습니다.\n정말 삭제하시겠습니까?`);
        } else {
          chkconfirm = window.confirm(`총 ${state.checkList.size}건 삭제하시겠습니까? `);
        }
        if (chkconfirm) {
          for (let item of state.checkList) {
            await _axios.delete(`/admin/diag/delete/${item}`, {}).catch(err => {
              console.log(err);
            });
          }
          alert(`총 ${state.checkList.size}건 삭제가 완료되었습니다.`);
        }
        getList(state.ResistIdx);
        getGroupList(state.ResistIdx);
        state.checkList.clear();
      }
    }

    function sampleExcelDwonLoad() {
      let url = '';
      if (location.origin.includes('local') || location.origin.includes('stage')) {
        url = `http://stage-api.carrotleaders.com/admin/diag/download-object?fileName=example.xlsx`;
      } else {
        url = `https://api.carrotleaders.com/admin/diag/download-object?fileName=example.xlsx`;
      }
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'SampleExcel.xlsx';
          link.click();
        });
    }
    async function excelDownLoad() {
      const FileDownload = require('js-file-download');
      let targetList = '';
      if (state.searchObj.searchMenu == '진단대상자별') {
        let tempobj = {
          idx_leadership_group: state.ResistIdx,
          status: state.searchObj.searchStatus,
          idx: state.allCheck ? '' : targetList,
        };
        tempobj[state.searchObj.searchType] = state.searchObj.searchText;
        let locationUrl = '';
        if (location.origin.includes('local') || location.origin.includes('stage')) {
          locationUrl = `https://stage-api.carrotleaders.com/admin/diag/subjectListExcel?idx=${targetList}&idx_leadership_group=${state.ResistIdx}&${state.searchObj.searchType}=${state.searchObj.searchText}`;
        } else {
          locationUrl = `https://api.carrotleaders.com/admin/diag/subjectListExcel?idx=${targetList}&idx_leadership_group=${state.ResistIdx}&${state.searchObj.searchType}=${state.searchObj.searchText}`;
        }

        _axios({
          url: locationUrl,
          method: 'GET',
          responseType: 'blob', // Important
        }).then(response => {
          FileDownload(response.data, 'List.xlsx');
        });
      } else {
        state.diagnosisList.forEach(el => {
          if (el.isChecked) {
            targetList += `${el.idx},`;
          }
        });
        let tempobj = {
          idx_leadership_group: state.ResistIdx,
          status: state.searchObj.searchStatus,
          idx: state.allCheck ? '' : targetList,
        };
        tempobj[state.searchObj.searchType] = state.searchObj.searchText;
        await _axios
          .get(`/admin/diag/list/excel`, {
            params: tempobj,
          })
          .then(res => {
            if (res.status == 200) {
              if (!state.allCheck) {
                if (location.origin.includes('local') || location.origin.includes('stage')) {
                  location.href = `http://stage-api.carrotleaders.com/admin/diag/list/excel?idx=${targetList}&idx_leadership_group=${state.ResistIdx}&${state.searchObj.searchType}=${state.searchObj.searchText}`;
                } else {
                  location.href = `https://api.carrotleaders.com/admin/diag/list/excel?idx=${targetList}&idx_leadership_group=${state.ResistIdx}&${state.searchObj.searchType}=${state.searchObj.searchText}`;
                }
              } else {
                if (location.origin.includes('local') || location.origin.includes('stage')) {
                  location.href = `http://stage-api.carrotleaders.com/admin/diag/list/excel?idx_leadership_group=${state.ResistIdx}`;
                } else {
                  location.href = `https://api.carrotleaders.com/admin/diag/list/excel?idx_leadership_group=${state.ResistIdx}`;
                }
              }
            }
          })
          .catch(err => {
            alert(`다운로드에 실패하였습니다. 관리자에게 문의하세요 \n ${err}`);
          });
      }
    }

    function clickTab($event) {
      state.searchObj.searchMenu = $event.target.textContent;
      if (state.searchObj.searchMenu == '진단대상자별') {
        state.searchObj.searchType = 'diag_subject_name';
        getSubjectList(route.query.idx);
      } else {
        state.searchObj.searchType = 'diag_subject_name';
        getList(route.query.idx);
      }
    }
    function chagneFilter($event) {
      state.searchObj.searchType = $event.target.value;
    }
    function initInputData() {
      state.groupValues.groupManagerName = '';
      state.groupValues.groupManagerEmailTtile = '';
      state.groupValues.groupEndData = '';
      state.groupValues.groupName = '';
      state.groupValues.groupStartData = '';
    }
    function setAnnouncementModal(textType, diagnosisType, messageText) {
      state.announcementType = textType;
      state.diagnosisType = diagnosisType;
      state.messageText = messageText;
      state.isAnnouncementModal = true;
    }
    async function guideSend() {
      const sendList = Array.from(state.checkList).join(',');
      if (state.announcementType === '메일') {
        const result = await _axios.post('/admin/diag/sendDiagMailOnly', { idx_leadership_group: Number(state.ResistIdx), idxList: sendList }, {});
        if (result.data.success) {
          alert(result.data.message);
          state.isAnnouncementModal = false;
        }
        return;
      }

      if (state.announcementType === '문자') {
        const result = await _axios.post('/admin/diag/sendDiagMmsOnly', { idx_leadership_group: Number(state.ResistIdx), idxList: sendList, message_txt: state.messageText }, {});
        if (result.data.success) {
          alert(result.data.message);
          setMessageModal(false);
          state.isAnnouncementModal = false;
        }
        return;
      }

      if (state.announcementType === '미진단') {
        // try {
        if (state.sendChk) {
          state.sendChk = false;
          const sendList = Array.from(state.checkList).join(',');

          const postRes = await _axios.post(
            '/admin/diag/sendDiagMail',
            {
              idxList: sendList,
              idx_leadership_group: Number(state.ResistIdx),
              type: '미진단',
            },
            headers
          );
          if (postRes.data.success) {
            alert(postRes.data.message);
            setMessageModal(false);
            state.isAnnouncementModal = false;
          }
          state.sendChk = true;
        }
        return;
      }
    }

    const onMovePageing = pageNumber => {
      // 페이지 이동 시 체크박스 초기화
      state.checkList.clear();
      state.allCheck = false;

      // 페이지 번호 설정
      state.pagingObj.pageNum = pageNumber;
      setPage.value = pageNumber;

      // 검색 메뉴에 따라 검색 함수 호출
      state.searchObj.searchMenu == '진단대상자별' ? searchSubhList() : searchList();
    };

    const searchList = async () => {
      await getList(route.query.idx);
    };

    const searchSubhList = async () => {
      await getSubjectList(route.query.idx);
    };

    const dateClick = $event => {
      $event.target.showPicker();
    };

    watch(
      () => route.query,
      async cur => {
        if (window.location.href.includes('Assignment') && window.location.href.includes('idx')) {
          state.isModify = true;
          await getGroup(route.query.idx);
          await getGroupList(route.query.idx);
          await getList(route.query.idx);
          state.ResistIdx = route.query.idx;
        } else if (window.location.href.includes('Assignment')) {
          state.isModify = false;
          initInputData();
          state.statistics = {};
          state.diagnosisList = [];
        }
      }
    );

    onMounted(async () => {
      if (route.query.idx !== undefined) {
        state.isModify = true;
        await getGroup(route.query.idx);
        await getGroupList(route.query.idx);
        await getList(route.query.idx);
        state.ResistIdx = route.query.idx;
      }
    });

    return {
      state,
      setAddDiagModal,
      addStarNumber,
      changeFile,
      clickChkBox,
      allChkBox,
      deleteItem,
      excelDownLoad,
      sampleExcelDwonLoad,
      setMessageModal,
      clickTab,
      chagneFilter,
      guideSend,
      setAnnouncementModal,
      onMovePageing,
      setPage,
      getGroup,
      searchList,
      searchSubhList,
      dateClick,
      addDiag,
      setMobileValue,
      setSubjectMobileValue,
      downloadDiagExample,
      uploadFile,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-excel {
  display: flex;
  justify-content: center;
  gap: 8px;
  max-width: 800px;
  margin-bottom: 40px;
  align-items: center;
  .add-excel-button {
    height: 38px;
    display: flex;
    gap: 8px;
    button {
      width: 120px;
    }
  }
}

.ta_info tr th {
  width: 110px;
  height: 50px;
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
  text-align: center;
}
.ta_info td {
  width: 110px;
  height: 50px;
  text-align: center;
  border: 1px solid #dee2e6;
}

.popup-wrapper {
  position: fixed;
  inset: 0;
  background: rgba($color: black, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .white-box {
    width: 500px;

    .close {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    .title {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 10px;
    }

    .w-150 {
      width: 160px;
    }
  }
}
</style>
